<template>
	<div class="teacher">
		<list-template
        :loading="loading"
			:total="total"
			:table-data="tableData"
			:table-config="tableConfig"
			:current-page="page"
			@onSearch="onSearch"
			@onReset="search = null"
			@onChangePage="changeCurrentPage"
			@onHandle="tableHandle"
			hasAdd
			@onAdd="onAdd"
		></list-template>
	</div>
</template>

<script>
import { putUser } from '@/api/account-management'
import { mapState } from 'vuex'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading:true,
			// 表格配置
			tableConfig: [
				{
					prop: 'name',
					label: '姓名'
				},
				{
					prop: 'roles_name',
					label: '角色名称'
				},
				{
					prop: 'mobile',
					label: '手机号'
				},
				{
					label: '操作',
					handle: true,
					width: 120,
					render: row => {
						if (row.status == 1) {
							return ['编辑', '禁用']
						} else return ['编辑', '启用']
					}
				}
			],
			search: null,
			total: 0,
			dialogVisible: false,
			// 表格中的数据
			tableData: []
		}
	},
	computed: {
		...mapState(['page'])
	},
	mounted() {
		this.$store.commit('setPage', 1)
		this.getData()
	},
	activated() {
		this.getData()
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit('setPage', e)
			this.getData()
		},
		/**
		 * 获取数据
		 */
		getData() {
			let { search, page } = this;
      this.loading = true;
			this.$_axios.get('user?roles=3', { params: { ...search, page } }).then(res => {
				let { data } = res.data
				this.tableData = data || [];
				let { total } = this.$tools.getPaginationInfo(res.headers)
				this.total = total
			}).finally(()=>this.loading = false);
		},
		// 新增按钮
		onAdd() {
			this.$router.push('./add')
		},
		// 搜索按钮被点击
		onSearch(val) {
			this.$store.commit('setPage', 1)
			this.search = val
			this.getData()
		},
		// 表格后面操作被点击
		tableHandle(row, handle, index) {
			if (index === 0) {
				this.$router.push({ path: './edit', query: { id: row.id } })
			} else {
				this.$confirm(handle + '账号?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
          this.$_axios2.get('api/teacher/account/status', { params: { id: row.id, status: row.status == 1 ? 0 : 1 } }).then(res => {
            if (res.data.status === 0) {
              row.status = row.status == 1 ? 0 : 1
              this.$message.success('操作成功')
            }
          })
				})
			}
		}
	}
}
</script>

<style scoped lang="scss">
.list {
	padding: 24rem 0 0 32rem;
	.search {
		.el-input,
		.el-select {
			width: 240rem;
			margin-right: 24rem;
		}
	}
	.pagination {
		margin-top: 50rem;
	}
}
</style>
